import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { candidateListApi } from '@Features/CandidateList'
import { profileApi } from '@Features/Profile'

const rootReducer = combineReducers({
  [candidateListApi.reducerPath]: candidateListApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        candidateListApi.middleware,
        profileApi.middleware
      ),
    preloadedState,
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store
