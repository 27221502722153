import {
  ListItem,
  ListItemText,
  Typography,
  Icons,
  ListItemExpansion,
  ChipGroup,
  Chip,
  ListItemButton,
} from '@trr/common-component-library'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { jobTitlesToUrlStrings, ICandidate } from '@Features/CandidateList'

interface ICandidateListItem {
  candidate: ICandidate
  listId: string
}

export const CandidateListItem: React.FunctionComponent<ICandidateListItem> = ({
  candidate,
  listId,
}) => {
  const history = useHistory()

  const handleOnClick = () => {
    const jobTitleQueryString = jobTitlesToUrlStrings(candidate.jobTitles).join(
      ';'
    )

    history.push(
      `/externa-kandidatlistor/profil?id=${
        candidate.id
      }&listId=${listId}&jobTitles=${encodeURIComponent(jobTitleQueryString)}`
    )
  }

  return (
    <ListItem expanded data-testid="candidate-list-item">
      <ListItemButton onClick={handleOnClick}>
        <ListItemText
          primary={<Typography variant="h3">{candidate.name}</Typography>}
          disablePrimaryTypography
        />
        <ListItemExpansion>
          <ChipGroup>
            {candidate.jobTitles.map((jobTitle) => (
              <Chip
                color="green"
                icon={<Icons.CheckmarkIcon />}
                key={jobTitle.id}
                data-testid="candidate-jobtitle-chip"
              >
                {jobTitle.name}
              </Chip>
            ))}
          </ChipGroup>
        </ListItemExpansion>
      </ListItemButton>
    </ListItem>
  )
}
