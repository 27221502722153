import getConfig from '@Utils/ConfigService/configService'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react/'

export const { API_URL } = getConfig()

export const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: (headers) => {
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })
