import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export const useSetBackButton = (url: string, reqParam = 'true') => {
  const elRef = useRef(null)
  const history = useHistory()

  useEffect(() => {
    const handleBackToList = (e: MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      history.push(url)
    }

    if (reqParam) {
      elRef.current = document.querySelector(
        "li[class^='Breadcrumbs_BreadcrumbItem'] > a"
      )

      elRef.current = elRef.current
        ? elRef.current
        : document.querySelector(
            "button[class^='Breadcrumbs_InternalBackLink']"
          )

      elRef.current?.addEventListener('click', handleBackToList)
      elRef.current?.href && elRef.current.setAttribute('href', url)
    }

    return () => {
      elRef.current?.removeEventListener('click', handleBackToList)
    }
  }, [url, reqParam, history])
}
