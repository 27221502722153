import {
  Typography,
  List,
  Loading,
  EmptyResult,
} from '@trr/common-component-library'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useEpiString } from '@Hooks/useEpiStrings'
import {
  useGetCandidateListQuery,
  candidateSuffix,
  CandidateListItem,
} from '@Features/CandidateList'
import { date } from '@trr/frontend-datetime'

import s from './CandidateList.module.scss'

export const CandidateList: React.FunctionComponent = () => {
  const t = useEpiString()
  const history = useHistory()
  const listId = new URLSearchParams(history.location.search).get('listId')
  const { data, isLoading, error } = useGetCandidateListQuery(listId as string)

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return (
      <EmptyResult
        heading={t('errorPage.heading')}
        description={t('errorPage.description')}
        classNames={{ root: s.EmptyResult }}
      />
    )
  }

  return (
    <div className={s.ListPageWrapper}>
      <Typography variant="h1" gutterBottom>
        {data.name}
      </Typography>
      <Typography variant="body1" gutterBottom className={s.BodyText}>
        {t('body.text')}
      </Typography>
      <div>
        <div className={s.numCandidatesWrapper}>
          <Typography variant="h5">
            {candidateSuffix(data.candidates.length)}
          </Typography>
        </div>
        <>
          {data.candidates.length > 0 ? (
            <>
              <List divider variant="large" classNames={{ root: s.ClientList }}>
                {data.candidates.map((candidate) => (
                  <CandidateListItem
                    candidate={candidate}
                    listId={data.id}
                    key={candidate.id}
                  />
                ))}
              </List>
              <Typography variant="body1" className={s.LatestUpdatedText}>
                {t('modified', {
                  date: date.format(data.modified, { style: 'numeric' }),
                })}
              </Typography>
            </>
          ) : (
            <EmptyResult
              heading={t('emptyResult.heading')}
              description={t('emptyResult.description')}
              classNames={{ root: s.EmptyResult }}
              data-testid="candidate-list-empty"
            />
          )}
        </>
      </div>
    </div>
  )
}
