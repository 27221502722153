export const convertToSwedishLocationTypes = (type: string) => {
  switch (type.toLocaleLowerCase()) {
    case 'county':
      return 'län'
    case 'city':
      return 'tätort'
    case 'municipality':
      return 'kommun'
    case 'country':
      return 'land'
    default:
      return type
  }
}
