export const downloadFile = (
  blob: Blob,
  filename: string,
  fileExt: string
): boolean => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  try {
    a.href = url
    a.target = '_blank'
    a.download = `${filename}.${fileExt}`
    document.body.appendChild(a)
    a.click()
    a.remove()
    return true
  } catch (error) {
    return false
  }
}

export const previewFile = (blob: Blob) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  try {
    a.href = url
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    a.remove()
    return true
  } catch (error) {
    return false
  }
}
