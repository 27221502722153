import { trrFetchBaseQuery } from '@Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'

import { ICandidateList } from '../types/candidateListApi.types'

export const candidateListApi = createApi({
  reducerPath: 'candidateListApi',
  baseQuery: trrFetchBaseQuery(),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getCandidateList: builder.query<ICandidateList, string>({
      query: (id) => `/CandidateLists/${id}`,
    }),
  }),
})

export const { useGetCandidateListQuery } = candidateListApi
