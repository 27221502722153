import { downloadFile, previewFile } from '../../../Utils/Helpers/fileHelper'
import { API_URL } from '../../../Utils/Network'

export const downloadCvQuery = ({
  pdfId,
  clientId,
  candidateListId,
  clientName,
}: {
  pdfId: string
  clientId: string
  candidateListId: string
  clientName: string
}) =>
  fetch(
    `${API_URL}/candidatelists/${candidateListId}/profiles/${clientId}/cvpdf/${pdfId}`,
    {
      headers: {
        'Accept-Language': 'sv',
        method: 'GET',
      },
    }
  )
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Faulty CV')
      }
      return res.blob()
    })
    .then((blob) => {
      downloadFile(blob, `${clientName} Cv`, 'pdf')
      return false
    })
    .catch(() => true)

export const previewCvQuery = ({
  pdfId,
  clientId,
  candidateListId,
}: {
  pdfId: string
  clientId: string
  candidateListId: string
}) =>
  fetch(
    `${API_URL}/candidatelists/${candidateListId}/profiles/${clientId}/cvpdf/${pdfId}`,
    {
      headers: {
        'Accept-Language': 'sv',
        method: 'GET',
      },
    }
  )
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Faulty CV')
      }
      return res.blob()
    })
    .then((blob) => {
      previewFile(blob)
      return false
    })
    .catch(() => true)
