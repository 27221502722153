import { trrFetchBaseQuery } from '@Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'
import { ProfileApiResponse } from '@Features/Profile'

interface IGetProfileParams {
  profileId: string
  listId: string
}

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getProfile: builder.query<ProfileApiResponse, IGetProfileParams>({
      query: ({ profileId, listId }) =>
        `/CandidateLists/${listId}/profiles/${profileId}`,
    }),
  }),
})

export const { useGetProfileQuery } = profileApi
