import {
  IMatchingProfileData,
  MatchingProfile,
} from '@trr/matching-profile-library'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useGetProfileQuery,
  convertToSwedishLocationTypes,
} from '@Features/Profile'
import { paramsToJobTitles } from '@Utils/Helpers/commonHelpers'
import {
  EmptyResult,
  Loading,
  SystemFeedback,
} from '@trr/common-component-library'
import { useEpiString } from '@Hooks/useEpiStrings'

import { useSetBackButton } from '../../Hooks/useSetBackButton/useSetBackButton'
import { downloadCvQuery, previewCvQuery } from '../../api/downloadFile'
import { useFeatureFlag } from '../../../../Utils/Hooks/useFeatureFlag'

import s from './Profile.module.scss'

export const Profile: React.FunctionComponent = () => {
  const history = useHistory()
  const t = useEpiString()
  const listId = new URLSearchParams(history.location.search).get('listId')
  const profileId = new URLSearchParams(history.location.search).get('id')
  const jobTitles = new URLSearchParams(history.location.search).get(
    'jobTitles'
  )
  const [isCvError, setisCvError] = useState(false)

  const {
    data: profileData,
    isLoading,
    isError,
  } = useGetProfileQuery({ listId, profileId })

  useSetBackButton(
    `/externa-kandidatlistor/lista?listId=${listId}`,
    (listId as string) ?? ''
  )

  const hideExperience = useFeatureFlag()('clientMatch.HideExperience')

  const downloadCv = async () => {
    setisCvError(
      await downloadCvQuery({
        pdfId: profileData?.cvPdf.id,
        clientId: profileId,
        candidateListId: listId,
        clientName: `${profileData?.personalInformation.firstName} ${profileData?.personalInformation.lastName}`,
      })
    )
  }

  const previewCv = async () => {
    setisCvError(
      await previewCvQuery({
        pdfId: profileData?.cvPdf.id,
        clientId: profileData?.id,
        candidateListId: listId,
      })
    )
  }

  const cleanError = () => {
    setisCvError(false)
  }

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return (
      <EmptyResult
        heading={t('errorPage.heading')}
        description={t('errorPage.description')}
        classNames={{ root: s.EmptyResult }}
      />
    )
  }

  const userProfile: IMatchingProfileData = {
    isError: isError,
    isLoading: isLoading,
    showCv: true,
    getCv: downloadCv,
    previewCv: previewCv,
    id: profileData.id,
    firstName: profileData.personalInformation.firstName,
    lastName: profileData.personalInformation.lastName,
    preferredFirstName: profileData.personalInformation.firstName,
    email: profileData.personalInformation.email,
    mobilePhone: profileData.personalInformation.mobilePhone,
    socialMedia: profileData.personalInformation.socialMedia,
    consent: false,
    matchingData: {
      searchTerms: {
        locations: [],
        jobTitles: paramsToJobTitles(jobTitles),
      },
      jobTitles: profileData.jobTitles,
      isSavedAfterMigration: true,
      locations:
        profileData.locationInformations.length > 0
          ? profileData.locationInformations.map(
              (item) =>
                `${item.name}, ${convertToSwedishLocationTypes(item.type)}`
            )
          : [],
      cvPdf: profileData.cvPdf,
      additionalInformation: profileData.additionalInformation,
    },
    hideExperience: hideExperience,
  }

  return (
    <div className={s.ProfileContainer}>
      {isCvError && (
        <SystemFeedback
          content="Profilen har inget giltigt CV"
          type="error"
          onClose={cleanError}
        />
      )}
      <MatchingProfile {...userProfile} />
    </div>
  )
}
