import React, { FunctionComponent } from 'react'
import { Provider } from 'react-redux'
import { CandidateList } from '@Features/CandidateList'
import { Profile } from '@Features/Profile'
import { EpiStringProvider } from '@Hooks/useEpiStrings'
import store from '@Store/configureStore'
import { AppShellDataProvider } from '@Utils/AppShellData'
import { AppShellRouter, KeyRoute } from '@Utils/Router'

import { IAppShellConfiguration } from './App.types'

export enum Routes {
  Kandidatlista = 'lista',
  Profil = 'profil',
}

const App: FunctionComponent<IAppShellConfiguration> = (appShellData) => (
  <Provider store={store}>
    <AppShellDataProvider value={appShellData}>
      <AppShellRouter currentKey={appShellData.currentKey}>
        <KeyRoute urlKey={Routes.Kandidatlista}>
          <EpiStringProvider value={appShellData.content.kandidatlista}>
            <CandidateList />
          </EpiStringProvider>
        </KeyRoute>
        <KeyRoute urlKey={Routes.Profil}>
          <EpiStringProvider value={appShellData.content.profil}>
            <Profile />
          </EpiStringProvider>
        </KeyRoute>
      </AppShellRouter>
    </AppShellDataProvider>
  </Provider>
)

export default App
