import { IJobTitle } from '../../Types'

export const paramsToJobTitles = (params: string): IJobTitle[] => {
  const splitted = params.split(';')
  const transformed = splitted.map((jobTitle) => ({
    name: jobTitle.split('|')[0],
    id: jobTitle.split('|')[1],
    experience: 0,
  }))
  return transformed
}
